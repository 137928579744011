import React from "react"
import Layout from "../../../components/Layout"
import { graphql, useStaticQuery } from "gatsby"
import { Container, Col, Row } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faDoorOpen,
  faWalking,
  faLock,
  faPhoneAlt,
  faLaptopHouse,
  faSuitcaseRolling,
} from "@fortawesome/free-solid-svg-icons"
import PartnerCarousel from "../../../components/PartnerCarousel"
import PeaceOfMindGuarantee from "../../../components/PeaceOfMindGuarantee"
import SectionTitle from "../../../components/SectionTitle"
import IconCards from "../../../components/business/IconCards"
import Services from "../../../components/Services"
import { Banner } from "../../../components/Banner"
import BusinessOffers from "../../../components/business/BusinessOffers"
import SEO from "../../../components/seo"

export default function Laptops() {
  const CardsWithIcons = [
    {
      icon: <FontAwesomeIcon icon={faSuitcaseRolling} />,
      title: "Mobile Office",
      description:
        "A mobile office combines the management and control of a computer suite, the freedom of portable computing and the power of the internet into a simple, affordable rolling solution.",
    },
    {
      icon: <FontAwesomeIcon icon={faDoorOpen} />,
      title: "Jump-start training anywhere",
      description:
        "Your access to technology is no longer limited to a single room or office. With no wires, no retro fitting and no installation required, the mobile office can turn any remote room or corner of the building into a powerful working centre.",
    },
    {
      icon: <FontAwesomeIcon icon={faWalking} />,
      title: "Totally flexible",
      description:
        "Laptops with mobile carts can be shared by colleagues in different rooms to maximise use",
    },
    {
      icon: <FontAwesomeIcon icon={faLock} />,
      title: "Security",
      description:
        "Keep your equipment secure with locking doors to ensure your laptops will be perfectly safe when they are not in use",
    },
    {
      icon: <FontAwesomeIcon icon={faPhoneAlt} />,
      title: "Communication",
      description:
        "Continuous uninterrupted contact with colleagues and stakeholders",
    },
    {
      icon: <FontAwesomeIcon icon={faLaptopHouse} />,
      title: "Work from Home",
      description:
        "Keep in touch with employees who work at home. Invest in virtual teamworking equipment including laptops, tablets and mobile phones.",
    },
  ]

  const data = useStaticQuery(graphql`
    query businessLaptops {
      laptopsAndTablets: file(
        relativePath: { eq: "services/AdobeStock_274709086.jpeg" }
      ) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
      storage: file(
        relativePath: { eq: "services/AdobeStock_101536916.jpeg" }
      ) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
      ITNetworks: file(
        relativePath: { eq: "services/AdobeStock_119201395.jpeg" }
      ) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
      Software: file(
        relativePath: { eq: "services/AdobeStock_317550082.jpeg" }
      ) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
      Maintenance: file(
        relativePath: { eq: "services/AdobeStock_308707407.jpeg" }
      ) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
      ChargingSolution: file(relativePath: { eq: "services/LapCabby.jpg" }) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
      BannerImage: file(
        relativePath: { eq: "banner/AdobeStock_297400808.jpeg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, maxHeight: 250) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const services = [
    {
      title: "Laptops & Tablets",
      description: "iMacs, Macbooks, Google Chromebooks and PCs",
      image: data.laptopsAndTablets.childImageSharp.fixed,
    },
    {
      title: "Storage",
      description: "Backup, NAS, Airport Time Capsule",
      image: data.storage.childImageSharp.fixed,
    },
    {
      title: "IT Networks",
      description: "Servers, Storage, Cabling, Switches and Web Filtering",
      image: data.ITNetworks.childImageSharp.fixed,
    },
    {
      title: "Software",
      description:
        "Mobile Device Management, Microsoft, Operating Systems, Adobe, User Monitoring",
      image: data.Software.childImageSharp.fixed,
    },
    {
      title: "Maintenance",
      description:
        "Extended Warranties, Telephone Support, Remote Support, Onsite Support, Installation",
      image: data.Maintenance.childImageSharp.fixed,
    },
    {
      title: "Charging Solutions",
      description:
        "Charging Trolley, Charge & Sync Trolley,  Charging Cabinets, Charging Flight Case",
      image: data.ChargingSolution.childImageSharp.fixed,
    },
  ]

  return (
    <Layout headerType="business">
      <SEO title="Compulease · Laptops Business" />
      <BusinessOffers />
      <Container>
        <Row>
          <Col>
            <SectionTitle
              title="Laptops and Tablets"
              description={
                <>
                  <p>
                    These days, portable technology is as powerful as desktop
                    technology. Add to that the versatility & flexibility it
                    provides to your business and your employees can be working
                    anytime, any place and anywhere. Employees can even continue
                    to work efficiently at remotely and in the evenings.
                  </p>
                  <p>
                    Compulease™ provides a whole range of portable solutions
                    from all the leading manufacturers, so whether your
                    requirement is for a single laptop, equipping your external
                    sales force, or a complete fully installed mobile office, we
                    have the solution for you.
                  </p>
                </>
              }
            />
          </Col>
        </Row>
      </Container>

      <IconCards
        title="Let us create a solution for your business"
        cards={CardsWithIcons}
      />

      <Container>
        <PeaceOfMindGuarantee />
        <Services title="Solutions we provide" services={services} />
        <Banner
          image={data.BannerImage.childImageSharp.fluid}
          title="Speak to our friendly customer service team for all your business computer leasing and finance needs"
          link={{
            text: "Get in touch",
            to: "/business/ContactUs/",
          }}
        />
        <PartnerCarousel type="business" />
      </Container>
    </Layout>
  )
}
